
import React from 'react';
import ChatBotComponentBase from '../../../shared/components/ChatBotComponentBase';

const welcomeMessages = [
    "👋 ¡Hola! Soy tu asistente digital del Ayuntamiento de Oliva. Puedo hablar contigo en Español, inglés, francés, holandés, y alemán. Estoy aquí para ayudarte con cualquier pregunta que tengas.",
    "Trabajo mejor con preguntas específicas, como '¿Dónde puedo pagar mis impuestos municipales de Oliva?'. 😊🎯",
    "¿Cómo puedo ayudarte hoy? 😊"
];

const predefinedQuestions = [
    { question: '¿Cuál es el horario de atención?', answer: 'El horario de atención al público del Ayuntamiento de Oliva es de lunes a viernes de 8:00 a 14:30 horas.' }
];

const ChatBotComponentOliva = () => (
    <ChatBotComponentBase
        title="Ayuntamiento de Oliva"
        logoSrc="build/assets/oliva-logo.jpg"
        welcomeMessages={welcomeMessages}
        predefinedQuestions={predefinedQuestions}
    />

);

export default ChatBotComponentOliva;

